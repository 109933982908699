import Vue from 'vue';
import VueMeta from 'vue-meta';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
import { animate } from 'animate.css';

import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import './quasar';

// import { version } from '../package.json';

Vue.config.productionTip = false;
Vue.use(VueMeta, { keyName: 'headMetaInfo' });

// Sentry.init({
//   Vue,
//   dsn: 'https://558c9f032d6e4d2fa7171971d174ebcc@o105377.ingest.sentry.io/5683641',
//   ignoreErrors: ['ResizeObserver loop limit exceeded'],
//   integrations: [new Integrations.BrowserTracing()],
//   release: version,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

new Vue({
  animate,
  i18n,
  router,
  store,
  render: (h) => h(App),
  mounted() {
    if (process.env.NODE_ENV === 'prerender') {
      /** 觸發 prerender 的時機 */
      document.dispatchEvent(new Event('trigger-prerender'));
    }
  },
}).$mount('#app');
