import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: 'AIzaSyCOdNDMsFWSRgG5cq6j7pVn1J-eMo9ehBc',
  authDomain: 'lollipop-27489.firebaseapp.com',
  databaseURL: 'https://lollipop-27489.firebaseio.com',
  projectId: 'lollipop-27489',
  storageBucket: 'lollipop-27489.appspot.com',
  messagingSenderId: '350312428604',
  appId: '1:350312428604:web:c323aa930b2c55d8b7bf39',
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
  is_regretting_countries: 'false',
};

const firebaseObj = {
  database,
  remoteConfig,
};

export default firebaseObj;
