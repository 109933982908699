import { fetchAndActivate, getValue } from 'firebase/remote-config';

import displayConfig from '../configs/displayConfig';
import firebaseObj from '../utils/firebaseUtil';

/**
 * 是否是 Care 價錢折扣時間
 * @returns {Boolean}
 */
function isCarePriceDiscountTime() {
  const { carePriceDiscount } = displayConfig;

  if (carePriceDiscount.deadline) {
    const currentTimestamp = Date.now();

    return currentTimestamp > carePriceDiscount.start && currentTimestamp < carePriceDiscount.end;
  }

  return true;
}

/**
 * 是否在免運有效期間內
 * @returns {Boolean}
 */
function isInFreeShippingDuration() {
  const { freeShipping } = displayConfig.noticeBar;

  if (freeShipping.deadline) {
    const currentTimestamp = Date.now();

    return currentTimestamp > freeShipping.start && currentTimestamp < freeShipping.end;
  }

  return true;
}

/**
 * 現在這個國家是否有免運活動
 * @returns {Boolean}
 */
function isCurrentCountryHasFreeShipping() {
  // 如果未來有需要分國家做 free shipping，
  // 可以取用 firebaseObj 的資料：firebaseObj.remoteConfig.getValue('free_shipping')

  return true;
}

/**
 * 是否 care plan price 有折扣
 * @returns {Boolean}
 */
export function getCarePriceDiscountStatus() {
  return isCarePriceDiscountTime();
}

/**
 * 取得 free shipping 狀態，是否顯示 free shipping(條件包含時間以及國家)
 * @returns {Boolean}
 */
export function getFreeShippingStatus() {
  return isInFreeShippingDuration() && isCurrentCountryHasFreeShipping();
}

/**
 * 取得 delay shipping 狀態，是否顯示 delay shipping
 * @returns {Boolean}
 */
export function getDelayShippingStatus() {
  return false;
}

export async function getRegretFreeShippingStatus() {
  const { remoteConfig } = firebaseObj;

  await fetchAndActivate(remoteConfig);
  const data = getValue(remoteConfig, 'is_regretting_countries');

  // eslint-disable-next-line no-underscore-dangle
  return data._value === 'true';
}
