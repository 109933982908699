<template>
  <div class="main-layout-container">
    <header class="header headroom">
      <mobile-main-header v-if="isMobile"></mobile-main-header>
      <main-header v-else :is-sub-menu-closed="isSubMenuClosed"></main-header>
    </header>
    <main class="main-page-content">
      <notice-bar v-if="displayConfig.noticeBar.root"></notice-bar>
      <router-view />
    </main>
    <footer class="footer">
      <main-footer></main-footer>
    </footer>
  </div>
</template>
<script>
import Headroom from 'headroom.js';
import { debounce } from 'quasar';
import { mapState } from 'vuex';

import MainHeader from './MainHeader.vue';
import MainFooter from './MainFooter.vue';
import MobileMainHeader from './MobileMainHeader.vue';
import NoticeBar from '../components/NoticeBar.vue';
import displayConfig from '../configs/displayConfig';

export default {
  name: 'main-layout',
  components: {
    MainHeader,
    MainFooter,
    MobileMainHeader,
    NoticeBar,
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
    headroomOffset() {
      if (this.isMobile) {
        return 56;
      }

      return 72;
    },
  },
  data() {
    return {
      displayConfig,
      isSubMenuClosed: true,
    };
  },
  mounted() {
    this.checkIsMobile();
    this.addResizeListener();
    this.$nextTick(() => {
      this.initHeaderAnimationEffect();
    });
  },
  methods: {
    initHeaderAnimationEffect() {
      const header = document.querySelector('header');
      const options = {
        offset: this.headroomOffset,
        onPin: () => {
          this.isSubMenuClosed = true;
        },
        onUnpin: () => {
          this.isSubMenuClosed = false;
        },
      };
      const headroom = new Headroom(header, options);
      headroom.init();
    },
    addResizeListener() {
      window.addEventListener('resize', debounce(this.checkIsMobile, 200));
    },
    checkIsMobile() {
      const isMobileLayout = (window.innerHeight > window.innerWidth * 1.2)
        || window.innerWidth <= 768;

      this.$store.commit('updateIsMobile', isMobileLayout);
    },
  },
};
</script>
<style lang="sass" scoped>
.main-layout-container
  header
    position: fixed
    top: 0
    width: 100%
    z-index: 3000
    color: $primary
    background-color: $primary
    &.headroom
      will-change: transform
      transition: transform 200ms linear
    &.headroom--top
      background-color: $primary
    &.headroom--pinned
      transform: translateY(0%)
    &.headroom--unpinned
      transform: translateY(-100%)
  main
    margin-top: $header-height

@media screen and (max-width: 768px)
  .main-layout-container
    main
      margin-top: $mobile-header-height

@media screen and (max-aspect-ratio: 5/6)
  .main-layout-container
    main
      margin-top: $mobile-header-height
</style>
