<template>
  <div class="delivery-bar-wrapper">
    <div class="delivery-bar-content">
      <div class="content-message">
        <i18n path="noticeBar.delivery.message" tag="div" for="DeliveryMsg">
          <a
            class="notice-text text-highlight"
            target="_self"
            href="https://shop.lollipop.camera/?utm_source=official_website&utm_medium=notice_bar&utm_campaign=worldwide_delivery">{{ $t('noticeBar.freeShipping.buyNow') }}
          </a>
        </i18n>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'delivery-bar',
};
</script>
<style lang="sass" scoped>
.text-highlight
  font-size: 16px
  font-weight: 700

.delivery-bar-wrapper
  --font-size: 16px
  background-color: transparent
  .delivery-bar-content
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    font-size: var(--font-size)
    line-height: 1.4
    font-weight: 400
    .content-message
      font-size: 400
      .inline-text
        display: inline-block
        white-space: pre-wrap
      a
        color: #1A7BE2
        text-decoration: underline
        margin-left: 12px

@media screen and (max-width: 360px)
  .text-highlight
    font-size: 14px
  .delivery-bar-wrapper
    --font-size: 12px
</style>
