<template>
  <nav
    class="m-nav-bar"
    :class="{ 'active': isMenuToggled, 'hide': !isMenuToggled }">
    <!-- Menu List -->
    <ul class="m-menu-list">
      <!-- Camera -->
      <li class="m-menu-item m-nav-camera">
        <div class="m-menu-item-container" @click="toggleCameraSubMenu">
          {{ $t('header.camera.title') }}
          <div
            class="dropdown icon-dropdown"
            :class="{'active': isCameraSubMenuActive}">
            <q-icon name="o_expand_more" />
          </div>
        </div>
        <!-- Sub Menu of Camera -->
        <ul
          class="m-sub-menu-list m-sub-menu-list-camera"
          :class="{'active': isCameraSubMenuActive}">
          <li class="sub-menu-item" @click="closeMenu">
            <router-link to="/product/camera">{{ $t('header.camera.babyCamera') }}</router-link>
          </li>
          <li class="sub-menu-item">
            <a :href="btnSensorStoreLink" target="_self">{{ $t('header.camera.sensor') }}</a>
          </li>
          <li class="sub-menu-item">
            <a :href="btnAccessoriesStoreLink" target="_self">
              {{ $t('header.camera.accessories') }}
            </a>
          </li>
        </ul>
        <!-- Sub Menu of Camera_End -->
      </li>
      <!-- Camera_End -->
      <!-- Care -->
      <li class="m-menu-item" @click="closeMenu">
        <router-link to="/product/care">{{ $t('header.camera.lollipopCare') }}</router-link>
      </li>
      <!-- Care_End -->
      <!-- Feeding -->
      <li class="m-menu-item">
        <div class="m-menu-item-container" @click="toggleFeedingSubMenu">
          {{ $t('header.feeding.title') }}
          <div
            class="dropdown icon-dropdown"
            :class="{'active': isFeedingSubMenuActive}">
            <q-icon name="o_expand_more" />
          </div>
        </div>
        <!-- Sub Menu of Feeding -->
        <ul
          class="m-sub-menu-list m-sub-menu-list-feeding"
          :class="{'active': isFeedingSubMenuActive}">
          <li class="sub-menu-item" @click="closeMenu">
            <router-link to="/product/tableware">{{ $t('header.feeding.tableware') }}</router-link>
          </li>
          <li class="sub-menu-item">
            <a :href="btnSilliconeFeedingBibLink" target="_self">
              {{ $t('header.feeding.bib') }}
            </a>
          </li>
        </ul>
        <!-- Sub Menu of Feeding_End -->
      </li>
      <!-- Feeding_End -->
      <!-- Support -->
      <li class="m-menu-item">
        <div class="m-menu-item-container" @click="toggleSupportSubMenu">
          {{ $t('header.support.title') }}
          <div
            class="dropdown icon-dropdown"
            :class="{'active': isSupportSubMenuActive}">
            <q-icon name="o_expand_more" />
          </div>
        </div>
        <!-- Sub Menu of Support -->
        <ul
          class="m-sub-menu-list m-sub-menu-list-support"
          :class="{'active': isSupportSubMenuActive}">
          <li class="sub-menu-item">
            <a href="https://support.lollipop.camera/hc/zh-tw" target="_blank">{{ $t('header.support.faq') }}</a>
          </li>
          <li class="sub-menu-item" @click="closeMenu">
            <a href="https://support.lollipop.camera/hc/en-us/requests/new" target="_blank">{{ $t('header.support.contactUs') }}</a>
          </li>
        </ul>
        <!-- Sub Menu of Support_End -->
      </li>
      <!-- Support_End -->
      <!-- About -->
      <li class="m-menu-item" @click="closeMenu">
        <router-link to="/about">{{ $t('header.about.title') }}</router-link>
      </li>
      <!-- About_End -->
    </ul>
    <!-- Menu List_End -->
    <!-- Kit List -->
    <div class="m-kit-list-container">
      <div class="m-kit-item kit-item-link web-app-wrapper">
        <a class="link-btn" href="https://my.lollipop.camera/?utm_source=official_website&utm_medium=mobile_menu" target="_blank">
          <img
            class="img-icon img-icon-account"
            src="/media/img/icons/header/account.svg?v=2"
            alt="Account">
            <div>{{ $t('header.account') }}</div>
        </a>
      </div>
      <div class="m-kit-item language-selector-wrapper">
        <language-selector :only-language-icon="false"></language-selector>
      </div>
    </div>
    <!-- Kit List_End -->
  </nav>
</template>
<script>
import { mapState } from 'vuex';
import LanguageSelector from '../components/LanguageSelector.vue';

export default {
  name: 'mobile-menu',
  components: {
    LanguageSelector,
  },
  props: {
    isMenuToggled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isCameraSubMenuActive: false,
      isFeedingSubMenuActive: false,
      isSupportSubMenuActive: false,
    };
  },
  computed: {
    ...mapState({
      languageCode: (state) => state.languageCode,
    }),
    btnSilliconeFeedingBibLink() {
      const url = `/product/sillicone-feeding-bib/?lang=${this.languageCode}`;
      return url;
    },
    btnSensorStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/products/lollipop-sensor-for-lollipop-baby-monitor-grey?variant=41463576232101'
        : 'https://shopee.tw/Lollipop-Sensor-%E6%A3%92%E6%A3%92%E7%B3%96%E7%9B%A3%E8%A6%96%E5%99%A8%E5%B0%88%E7%94%A8%E6%BA%AB%E6%BF%95%E5%BA%A6%E6%84%9F%E6%B8%AC%E5%99%A8(%E4%B8%8D%E5%8F%AF%E5%96%AE%E7%8D%A8%E4%BD%BF%E7%94%A8)-i.63372023.1097792525?sp_atk=558b0c8a-1aa5-49b5-a723-729d03ceabe5&xptdk=558b0c8a-1aa5-49b5-a723-729d03ceabe5';
    },
    btnAccessoriesStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/collections/lollipop-accessories'
        : 'https://shopee.tw/shop/63372023/search?page=0&shopCollection=247173350';
    },
  },
  methods: {
    closeMenu() {
      this.reset();
      this.$emit('close-menu');
    },
    toggleCameraSubMenu() {
      const temp = !this.isCameraSubMenuActive;
      this.reset();
      this.isCameraSubMenuActive = temp;
    },
    toggleFeedingSubMenu() {
      const temp = !this.isFeedingSubMenuActive;
      this.reset();
      this.isFeedingSubMenuActive = temp;
    },
    toggleSupportSubMenu() {
      const temp = !this.isSupportSubMenuActive;
      this.reset();
      this.isSupportSubMenuActive = temp;
    },
    reset() {
      this.isCameraSubMenuActive = false;
      this.isFeedingSubMenuActive = false;
      this.isSupportSubMenuActive = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.m-nav-bar
  position: fixed
  top: $mobile-header-height
  z-index: 2500
  height: 100vh
  width: 100vw
  background-color: $primary
  color: #EDEDED
  padding: 8px
  &.active
    animation: animate-mobile-menu-down .5s ease-out
  &.hide
    animation: animate-mobile-menu-up .5s ease-in-out
    display: none
  .m-menu-list
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 24px
    .m-menu-item
      display: flex
      flex-direction: column
      align-items: flex-start
      font-size: 24px
      line-height: 32px
      font-weight: 400
      margin: 12px 0
      .m-menu-item-container
        display: flex
        .icon-dropdown
          &.active
            transform: rotate(180deg)
            margin-bottom: -4px
      .m-sub-menu-list
        display: none
        flex-direction: column
        align-items: flex-start
        font-size: 16px
        line-height: 20px
        font-weight: 400
        &.active
          display: flex
        .sub-menu-item
          margin: 4px 0
  .m-kit-list-container
    display: flex
    flex-direction: column
    position: absolute
    left: 50%
    bottom: 25%
    transform: translate(-50%, 0)
    font-size: 18px
    font-weight: 400
    .m-kit-item
      display: flex
      justify-content: center
      margin: 8px 0
      .link-btn
        display: flex
        align-items: flex-start
        color: white
        .img-icon
          margin-right: 8px

@media screen and (min-width: 576px)
  .m-nav-bar
    .m-menu-list
      .m-menu-item
        font-size: 32px
        line-height: 44px
        .m-sub-menu-list
          font-size: 18px
          line-height: 28px
/** Menu_End */

@keyframes animate-mobile-menu-down
  0%
    top: -100vh
  100%
    top: $mobile-header-height
@keyframes animate-mobile-menu-up
  0%
    top: $mobile-header-height
  100%
    top: -100vh
</style>
