<template>
  <div class="notice-bar-container">
    <div v-if="isBlackFridayBarActive" class="content-outer-wrapper black-friday-bar">
      <black-friday-bar></black-friday-bar>
    </div>
    <div v-else-if="isHolidayShippingBarActive" class="content-outer-wrapper holiday-shipping-bar">
      <holiday-shipping-bar holiday-name="tomb_sweeping_day_2022"></holiday-shipping-bar>
    </div>
    <div v-else-if="isDelayShippingActive" class="content-outer-wrapper delay-shipping-bar">
      <delay-shipping-bar></delay-shipping-bar>
    </div>
    <div v-else-if="hasNoticeBarByLanguage" class="content-outer-wrapper free-shipping-bar">
      <free-shipping-bar v-if="hasFreeShipping"></free-shipping-bar>
      <delivery-bar v-else></delivery-bar>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getFreeShippingStatus, getDelayShippingStatus } from '../services/marketing';
import FreeShippingBar from './marketing/FreeShippingBar.vue';
import BlackFridayBar from './marketing/BlackFridayBar.vue';
import HolidayShippingBar from './marketing/HolidayShippingBar.vue';
import DelayShippingBar from './marketing/DelayShippingBar.vue';
import DeliveryBar from './marketing/DeliveryBar.vue';

export default {
  name: 'notice-bar',
  components: {
    FreeShippingBar,
    BlackFridayBar,
    HolidayShippingBar,
    DelayShippingBar,
    DeliveryBar,
  },
  computed: {
    ...mapState({
      languageCode: (state) => state.languageCode,
    }),
    hasFreeShipping() {
      return getFreeShippingStatus();
    },
    isBlackFridayBarActive() {
      const endTimestamp = 1638374400000;

      return Date.now() < endTimestamp;
    },
    isHolidayShippingBarActive() {
      // 較長假期的延後出貨提醒通知
      const startTimestamp = 1648774800000;
      const endTimestamp = 1649206800000;

      return Date.now() > startTimestamp && Date.now() < endTimestamp;
    },
    isDelayShippingActive() {
      return getDelayShippingStatus();
    },
    hasNoticeBarByLanguage() {
      return this.languageCode !== 'zh-Hant';
    },
  },
};
</script>
<style lang="sass" scoped>
.notice-bar-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  position: relative
  z-index: 1000
  .content-outer-wrapper
    width: 100%
    position: relative
    display: flex
    justify-content: center
    align-items: center
    padding: 12px 48px
    &.free-shipping-bar
      background-color: #ffcccc
    &.black-friday-bar
      background-color: #ffcccc
    &.holiday-shipping-bar
      background-color: #ffcccc
    &.delay-shipping-bar
      background-color: #ffcccc

/** Mobile */
@media screen and (max-width: 414px)
  .notice-bar-container
    .content-outer-wrapper
      padding: 12px 36px

@media screen and (max-width: 360px)
  .notice-bar-container
    .content-outer-wrapper
      padding: 12px 28px
</style>
