const shippingTermsLinkMap = new Map();

// TODO: 缺繁中版
shippingTermsLinkMap.set('en', 'https://support.lollipop.camera/hc/en-us/articles/6154434211993-Shipping-Terms-and-Conditions');
shippingTermsLinkMap.set('zh-Hant', 'https://support.lollipop.camera/hc/en-us/articles/6154434211993-Shipping-Terms-and-Conditions');
shippingTermsLinkMap.set('ja', 'https://support.lollipop.camera/hc/en-us/articles/6154788087321');
shippingTermsLinkMap.set('de', 'https://support.lollipop.camera/hc/en-us/articles/6154549030425');
shippingTermsLinkMap.set('es', 'https://support.lollipop.camera/hc/en-us/articles/6154773394201');
shippingTermsLinkMap.set('fr', 'https://support.lollipop.camera/hc/en-us/articles/6154585878553');
shippingTermsLinkMap.set('it', 'https://support.lollipop.camera/hc/en-us/articles/6154791586969');

export default shippingTermsLinkMap;
