<template>
  <div class="delay-shipping-bar-wrapper">
    <div class="delay-shipping-bar-content">
      <div class="content-message">
        <i18n path="noticeBar.delayShipping.message" tag="div" for="DelayShippingMsg"></i18n>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'delay-shipping-bar',
};
</script>
<style lang="sass" scoped>
.link-text-highlight
  font-size: 16px
  font-weight: 700
  &:hover
    text-decoration: underline

.delay-shipping-bar-wrapper
  --font-size: 16px
  background-color: transparent
  .delay-shipping-bar-content
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    font-size: var(--font-size)
    line-height: 1.4
    font-weight: 400
    .content-message
      font-size: 400
      .inline-text
        display: inline-block
        white-space: pre-wrap
    .content-buy
      margin-left: 20px
      a
        color: #1A7BE2
        text-decoration: underline

@media screen and (max-width: 360px)
  .delay-shipping-bar-wrapper
    --font-size: 14px
</style>
