<template>
  <div class="holiday-shipping-bar-wrapper">
    <div class="holiday-shipping-bar-content">
      <div class="content-message">
        <i18n path="noticeBar.holidayShipping.message" tag="div" for="HolidayShippingMsg">
          <template v-slot:free_shipping>
            <a
              class="link-text-highlight"
              target="_self"
              :href="`https://shop.lollipop.camera/collections/lollipop-baby-camera/products/lollipop-baby-camera-us?variant=41463118135461&utm_source=official_website&utm_medium=holiday_shipping_bar&utm_campaign=${holidayName}_free_shipping`">
              {{ $t('noticeBar.holidayShipping.msg_free_shipping') }}
            </a>
          </template>
          <template v-slot:shipping_date>
            {{ $t('noticeBar.holidayShipping.msg_shipping_date') }}
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'holiday-shipping-bar',
  props: {
    holidayName: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="sass" scoped>
.link-text-highlight
  font-size: 16px
  font-weight: 700
  &:hover
    text-decoration: underline

.holiday-shipping-bar-wrapper
  --font-size: 14px
  background-color: transparent
  .holiday-shipping-bar-content
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    font-size: var(--font-size)
    line-height: 1.4
    font-weight: 400
    .content-message
      font-size: 400
      .inline-text
        display: inline-block
        white-space: pre-wrap
    .content-buy
      margin-left: 20px
      a
        color: #1A7BE2
        text-decoration: underline

@media screen and (max-width: 360px)
  .holiday-shipping-bar-wrapper
    --font-size: 12px
</style>
