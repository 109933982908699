import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';

import Quasar from 'quasar/src/vue-plugin.js';import QIcon from 'quasar/src/components/icon/QIcon.js';;

Vue.use(Quasar, {
  components: {
    QIcon,
  },
  config: {},
  plugins: {
  },
});
