import Vue from 'vue';
import Vuex from 'vuex';
import $i18n from '../i18n';

Vue.use(Vuex);

const initState = {
  // 頁面 sale-channel 所選擇的國家
  countryCode: 'us',
  // 使用者所在位置
  geolocation: undefined,
  isMobile: false,
  isCookieAccepted: false,
  /** Header 是否要固定位置 */
  isHeaderFixed: true,
  languageCode: undefined,
};

const mutations = {
  updateCountryCode(state, payload) {
    state.countryCode = payload;
  },
  updateGeolocation(state, payload) {
    state.geolocation = payload;
    localStorage.setItem('lollipop-geolocation', payload);
  },
  updateIsMobile(state, payload) {
    state.isMobile = payload;
  },
  updateIsCookieAccepted(state, payload) {
    state.isCookieAccepted = payload;
  },
  updateIsHeaderFixed(state, payload) {
    state.isHeaderFixed = payload;
  },
  updateLanguageCode(state, payload) {
    state.languageCode = payload;
    $i18n.locale = payload;
  },
};

const actions = {
  async getGeolocation({ commit }) {
    const geolocation = await fetch('https://ipinfo.io/?token=4f42acecb5ff1b')
      .then((response) => response.json())
      .then((data) => data.country.toLowerCase())
      .catch(() => 'us');

    commit('updateGeolocation', geolocation);

    return geolocation;
  },
};

export default new Vuex.Store({
  state: initState,
  mutations,
  actions,
});
