const languageMap = new Map();

/** 排列的順序就是顯示在 language selector 視窗的順序 */
languageMap.set('en', 'English');
languageMap.set('zh-Hant', '繁體中文');
languageMap.set('ja', '日本語');
languageMap.set('de', 'Deutsch');
languageMap.set('es', 'Español');
languageMap.set('fr', 'français');
languageMap.set('it', 'Italiano');

export default languageMap;
