<template>
  <div class="footer-inner-wrapper">
    <div class="footer-container">
      <div class="footer-directory-container">
        <div class="footer-logo">
          <router-link to="/">
            <img class="img-icon-logo" alt="Lollipop Logo" src="/media/img/logo.svg" />
          </router-link>
        </div>
        <div class="footer-directory-column-list">
          <div class="footer-directory-column products">
            <label for="products">{{ $t('footer.product.title') }}</label>
            <div class="divider"></div>
            <ul>
              <li>
                <router-link to="/product/camera">{{ $t('footer.product.camera') }}</router-link>
              </li>
              <li>
                <router-link to="/product/care">{{ $t('footer.product.care') }}</router-link>
              </li>
              <li>
                <a :href="btnAccessoriesStoreLink" target="_self">
                  {{ $t('footer.product.accessory') }}
                </a>
              </li>
              <li>
                <router-link to="/product/tableware">
                  {{ $t('footer.product.tableware') }}
                </router-link>
              </li>
              <li>
                <a :href="btnSilliconeFeedingBibLink" target="_self">
                  {{ $t('footer.product.bib') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-directory-column">
            <label>{{ $t('footer.store.title') }}</label>
            <div class="divider"></div>
            <ul>
              <li>
                <router-link to="/sale-channel">{{ $t('footer.store.home') }}</router-link>
              </li>
              <li>
                <a :href="btnOfficialStoreLink" target="_self">
                  {{ $t('footer.store.main') }}
                </a>
              </li>
              <li>
                <a :href="shippingTermsLink" target="_blank">
                  {{ $t('footer.store.shipping') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-directory-column">
            <label>{{ $t('footer.help.title') }}</label>
            <div class="divider"></div>
            <ul>
              <li>
                <a href="https://my.lollipop.camera/login" target="_blank">
                  {{ $t('footer.help.webApp') }}
                </a>
              </li>
              <li>
                <a href="https://support.lollipop.camera/hc/en-us/sections/360006906532-Setup-Installation" target="_blank">
                  {{ $t('footer.help.guide') }}
                </a>
              </li>
              <li>
                <a href="https://support.lollipop.camera/hc/en-us" target="_blank">
                  {{ $t('footer.help.faq') }}
                </a>
              </li>
              <li>
                <a href="https://support.lollipop.camera/hc/en-us/categories/360002199911-Warranty-and-Safety" target="_blank">
                  {{ $t('footer.help.warranty') }}
                </a>
              </li>
              <li>
                <a href="https://support.lollipop.camera/hc/en-us/categories/900000112883-Privacy-Terms" target="_blank">
                  {{ $t('footer.help.privacyAndTerm') }}
                </a>
              </li>
              <li>
                <a href="https://support.lollipop.camera/hc/en-us/requests/new" target="_blank">
                  {{ $t('footer.help.support') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-directory-column">
            <label>{{ $t('footer.contact.title') }}</label>
            <div class="divider"></div>
            <ul>
              <li>
                <router-link to="/about">{{ $t('footer.contact.about') }}</router-link>
              </li>
              <li>
                <a href="mailto:distribution@lollipop.camera">
                  {{ $t('footer.contact.business') }}
                </a>
              </li>
              <li>
                <a href="mailto:collaboration@lollipop.camera">
                  {{ $t('footer.contact.collaboration') }}
                </a>
              </li>
              <li>
                <a href="mailto:media.help@lollipop.camera">
                  {{ $t('footer.contact.media') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isMobile" class="footer-language-selector-wrapper">
          <language-selector :is-footer="true"></language-selector>
        </div>
      </div>
      <div class="footer-legal-container">
        <ul class="support-list">
          <li>
              <a href="https://support.lollipop.camera/hc/en-us/articles/900001317943-Terms-and-Conditions" target="_blank">
              {{ $t('footer.support.terms') }}</a>
            </li>
          <li>
            <a href="https://support.lollipop.camera/hc/en-us/articles/900001317923-Privacy-Policy" target="_blank">
            {{ $t('footer.support.privacy') }}</a>
          </li>
          <li>
              <a href="https://support.lollipop.camera/hc/en-us/articles/900004731383-Cookie-Policy-for-Lollipop" target="_blank">
              {{ $t('footer.support.cookie') }}</a>
          </li>
          <li>
            © 2023 {{ $t('footer.support.companyName') }}
            {{ $t('footer.support.version') }} {{version}}
          </li>
        </ul>
        <ul class="social-media-list">
          <li class="social-media-item">
            <a href="https://www.instagram.com/lollipopbabycamera/" target="_blank">
              <img src="/media/img/icons/social_media/instagram_white.svg" alt="icon_instagram">
            </a>
          </li>
          <li class="social-media-item">
            <a href="https://www.facebook.com/LollipopBabyCam/" target="_blank">
              <img src="/media/img/icons/social_media/facebook_white.svg" alt="icon_facebook">
            </a>
          </li>
          <li class="social-media-item">
            <a href="https://www.youtube.com/channel/UCf4PRyXYM29p1HGKwosZtRw" target="_blank">
              <img src="/media/img/icons/social_media/youtube_white.svg" alt="icon_youtube">
            </a>
          </li>
          <li class="social-media-item">
            <a href="https://www.pinterest.com/lollipopbabycam/" target="_blank">
              <img src="/media/img/icons/social_media/pinterest_white.svg" alt="icon_pinterest">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { version } from '../../package.json';
import shippingTermsLinkMap from '../enums/shippingTermsLinkMap';
import LanguageSelector from '../components/LanguageSelector.vue';

export default {
  name: 'main-footer',
  components: {
    LanguageSelector,
  },
  data() {
    return {
      version,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      languageCode: (state) => state.languageCode,
    }),
    shippingTermsLink() {
      return shippingTermsLinkMap.get(this.languageCode);
    },
    btnSilliconeFeedingBibLink() {
      const url = `/product/sillicone-feeding-bib/?lang=${this.languageCode}`;
      return url;
    },
    btnOfficialStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/'
        : 'https://shopee.tw/lollipopbabycarestoretw?shopCollection=247173941#product_list';
    },
    btnAccessoriesStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/collections/lollipop-accessories'
        : 'https://shopee.tw/shop/63372023/search?page=0&shopCollection=247173350';
    },
  },
};
</script>
<style lang="sass" scoped>
.footer-inner-wrapper
  --column-font-size: 14px
  --legal-font-size: 14px
  --column-list-width: 70vw
  --language-selector-width: 100px
  --social-media-img: 32px
  width: 100%
  overflow: hidden
  background-color: $primary
  display: flex
  justify-content: center
  .footer-container
    display: flex
    flex-direction: column
    width: $max-content-width
    padding: 2vw 0 12px
    .footer-directory-container
      display: flex
      flex-direction: row
      position: relative
      margin-bottom: 72px
      .footer-logo
        width: 160px
        align-self: flex-start
        margin: 2vw 2vw 0 0
        img
          width: 100%
          height: auto
      .footer-directory-column-list
        width: calc( var(--column-list-width) - var(--language-selector-width) )
        display: flex
        flex-direction: row
        flex-wrap: wrap
        .footer-directory-column
          width: 140px
          font-size: var(--column-font-size)
          color: $primary-light
          margin: 2vw
          text-align: left
          label
            line-height: 1.2
            font-weight: 700
          .divider
            width: 100%
            height: 1px
            background-color: rgba(255, 255, 255, 0.5)
            margin: 8px 0 8px -4px
          ul
            li
              margin: 4px 0
              a
                &:hover
                  text-decoration: underline
      .footer-language-selector-wrapper
        color: $primary-light
        position: absolute
        right: 0
        transform: translate(0, 0)
        margin-top: 2vw
    .footer-legal-container
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: 0px 16px 20px 16px
      ul
        display: flex
        margin-top: 12px
        &.support-list
          color: $primary-light
          order: 2
        &.social-media-list
          order: 1
        li
          font-size: var(--legal-font-size)
          line-height: 1.2
          font-weight: 400
          margin: 0 16px
          &.social-media-item
            margin: 0 12px
            a
              img
                width: var(--social-media-img)
                height: var(--social-media-img)

/** Large Screen */
@media screen and (min-width: 1600px)
  .footer-inner-wrapper
    .footer-container
      width: $max-content-width-xl

@media screen and (max-width: 1200px)
  .footer-inner-wrapper
    --column-list-width: 65vw
    .footer-container
      padding: 48px 4vw 12px

/** Mobile */
@media only screen and (max-width: 768px)
  .footer-inner-wrapper
    --column-font-size: 16px
    --legal-font-size: 14px
    .footer-container
      padding: 48px 12vw 12px
      .footer-directory-container
        flex-direction: column
        .footer-logo
          width: 160px
          align-self: center
          margin: 0 0 2vh 0
          img
            width: 100%
            height: auto
        .footer-directory-column-list
          width: 100%
          flex-direction: column
          .footer-directory-column
            width: 100%
            margin: 2vh 0
            text-align: left
            &:first-child
              margin: 2vh 0
            &:last-child
              margin: 2vh 0
      .footer-legal-container
        flex-direction: column
        align-items: center
        padding: 0px 6vw 12px 6vw
        ul
          flex-wrap: wrap
          justify-content: center
          margin-top: 8px
          &.support-list
            order: 2
          &.social-media-list
            order: 1
          li
            margin: 4px 16px
            &.social-media-item
              margin: 0 8px

@media only screen and (max-width: 320px)
  .footer-inner-wrapper
    --column-font-size: 14px
    --legal-font-size: 12px

/** iPad Pro */
@media screen and (min-width: 960px) and (min-aspect-ratio: 3/5) and (max-aspect-ratio: 9/10)
  .footer-inner-wrapper
    --column-font-size: 20px
    --legal-font-size: 18px
    --social-media-img: 36px
    .footer-container
      padding: 48px 12vw 12px
      .footer-directory-container
        flex-direction: column
        .footer-logo
          width: 240px
          align-self: center
          margin: 0 0 2vh 0
        .footer-directory-column-list
          width: 100%
          flex-direction: column
          .footer-directory-column
            width: 100%
            margin: 2vh 0
            text-align: left
            &:first-child
              margin: 2vh 0
            &:last-child
              margin: 2vh 0
      .footer-legal-container
        flex-direction: column
        align-items: center
        padding: 0px 6vw 12px 6vw
        ul
          flex-wrap: wrap
          justify-content: center
          margin-top: 8px
          &.support-list
            order: 2
          &.social-media-list
            order: 1
          li
            margin: 4px 16px
</style>
