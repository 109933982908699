<template>
  <div class="m-main-header-wrapper">
    <!-- Mobile Header -->
    <div class="m-header-bar">
      <div class="m-menu-container" @click="toggleMenu">
        <q-icon v-if="!isMenuToggled" class="icon-header" name="o_menu" />
        <q-icon v-else class="icon-header" name="o_close" />
      </div>
      <div class="m-header-logo">
        <router-link to="/" class="img-icon-logo-wrapper">
          <img
            class="img-icon-logo"
            src="/media/img/logo.svg"
            alt="Lollipop Logo"
            @click="closeMenu"/>
        </router-link>
      </div>
      <div class="m-header-bar-kits-container">
        <div class="m-kit-item">
          <a :href="btnOfficialStoreLink" target="_self" class="img-icon-wrapper">
            <div class="link-btn">
              <img
                class="m-img-icon m-img-icon-store"
                src="/media/img/icons/header/shopping_cart.svg?v=2"
                alt="Shopping Cart">
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- Mobile Header_End -->
    <mobile-menu
      :is-menu-toggled="isMenuToggled"
      @close-menu="closeMenu">
    </mobile-menu>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import MobileMenu from './MobileMenu.vue';

export default {
  name: 'mobile-main-header',
  components: {
    MobileMenu,
  },
  data() {
    return {
      isMenuToggled: false,
    };
  },
  computed: {
    ...mapState({
      languageCode: (state) => state.languageCode,
    }),
    btnOfficialStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/'
        : 'https://shopee.tw/lollipopbabycarestoretw?shopCollection=247173941#product_list';
    },
  },
  watch: {
    isMenuToggled(value) {
      if (value) {
        document.getElementsByTagName('html')[0].classList.add('is-clipped');
      } else {
        document.getElementsByTagName('html')[0].classList.remove('is-clipped');
      }
    },
  },
  methods: {
    closeMenu() {
      this.isMenuToggled = false;
    },
    toggleMenu() {
      this.isMenuToggled = !this.isMenuToggled;
    },
  },
};
</script>
<style lang="sass" scoped>
.m-main-header-wrapper
  width: 100vw
  .m-header-bar
    position: relative
    height: $mobile-header-height
    padding: 0 8px
    .m-menu-container
      position: absolute
      top: 0
      .icon-header
        display: flex
        padding: 0 8px
        font-size: 24px
        font-weight: 400
        color: $main-light-color
        height: $mobile-header-height
    .m-header-logo
      height: 100%
      position: absolute
      top: 0
      left: 50%
      transform: translate(-50%, 0)
      display: flex
      justify-content: center
      align-items: center
      .img-icon-logo-wrapper
        display: flex
        img
          height: 32px
          width: auto
    .m-header-bar-kits-container
      height: 100%
      position: absolute
      top: 0
      right: 0
      transform: translate(-50%, 0)
      padding: 0 4px
      .m-kit-item
        height: 100%
        display: flex
        .img-icon-wrapper
          height: 100%
          .link-btn
            height: 100%
            display: flex
            align-items: center
            .m-img-icon
              height: 20px
              width: auto

</style>
