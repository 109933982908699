const displayConfig = {
  noticeBar: {
    // 是否顯示 notice bar
    root: true,
    freeShipping: {
      deadline: true,
      // 當 deadline 為 true 時，才會判斷起訖時間
      start: 1693526400000,
      end: 1696118400000,
    },
  },
  carePriceDiscount: {
    // 當 deadline 為 true 時，才會判斷起訖時間
    deadline: true,
    start: 1704556800000,
    end: 1706716800000,
  },
  // 是否兩種方案 (origin/pro) 並存
  isPlanCompatible: false,
};

export default displayConfig;
