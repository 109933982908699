<template>
  <div class="language-selector-container">
    <div
      class="language-selector-btn"
      :class="{'is-footer': isFooter}"
      @click="selectLanguage">
      <img
        class="img-icon language-selector-icon"
        src="/media/img/icons/header/language.svg"
        alt="icon_language">
      <div v-if="!onlyLanguageIcon" class="language-selector-text">{{ language }}</div>
    </div>
    <div class="language-selector-dialog-wrapper">
      <q-dialog v-model="popupSelector" :maximized="isMobile">
        <q-card class="language-selector-card">
          <q-card-section class="row items-center q-pb-none">
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>

          <div class="language-list-container">
            <div class="text-content-desc-sm language-list-title">
              {{ $t('header.languageDialog.title') }}
            </div>
            <div class="language-list-wrapper">
              <ul>
                <li
                  v-for="item of languageMap" :key="item[0]"
                  @click="changeLanguage(item[0])">
                  {{ item[1] }}
                </li>
              </ul>
            </div>
          </div>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import languageMap from '../enums/languageMap';

export default {
  name: 'language-selector',
  props: {
    isFooter: {
      default: false,
    },
    onlyLanguageIcon: {
      default: false,
    },
  },
  data() {
    return {
      popupSelector: false,
      languageMap,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      languageCode: (state) => state.languageCode,
    }),
    language() {
      return languageMap.get(this.languageCode);
    },
  },
  methods: {
    selectLanguage() {
      this.popupSelector = true;
    },
    changeLanguage(selectedLanguageCode) {
      this.$store.commit('updateLanguageCode', selectedLanguageCode);
      this.$router.replace({ query: { lang: selectedLanguageCode } });
      this.popupSelector = false;
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../styles/global.variables'

.language-selector-btn
  display: flex
  align-items: center
  cursor: pointer
  &:hover
    font-weight: 600
  &.is-footer
    .language-selector-icon
      width: 14px
      height: 14px
      margin-right: 8px
    .language-selector-text
      font-size: 14px
      line-height: 18px
  .language-selector-icon
    width: 20px
    height: 20px
    margin-right: 8px
  .language-selector-text
    font-size: 18px
    line-height: 24px
    vertical-align: middle
.language-selector-card
  width: 740px
  max-width: 800px
  height: 580px
  max-height: 100vh
  background-color: $primary
  color: $main-light-color
  .language-list-container
    padding: 0 36px 36px 36px
    .language-list-title
      color: $main-light-color
      font-weight: 700
    .language-list-wrapper
      padding: 24px 32px
      ul
        display: flex
        flex-wrap: wrap
        li
          width: 100px
          margin-right: 48px
          margin-bottom: 12px
          &:hover
            color: $lollipop-blue

/** Mobile */
@media only screen and (max-width: 768px)
  .language-selector-card
    width: 100vw
    height: 100vh
    .language-list-container
      .language-list-title
      .language-list-wrapper
        ul
          flex-direction: column
</style>
