<template>
  <div class="free-shipping-bar-wrapper">
    <div v-if="isRegretFreeShipping" class="free-shipping-bar-content">
      <div class="content-message">
        <div class="inline-text">
          <i18n path="noticeBar.freeShipping.regrettingMsg" tag="div" for="regrettingFreeShipping">
            <a class="notice-text" target="_self" href="https://shop.lollipop.camera/?utm_source=official_website&utm_medium=free_shipping_bar&utm_campaign=regret_free_shipping">
              <span class="text-highlight">{{ $t('noticeBar.freeShipping.regrettingMsg_0') }}</span>
            </a>
          </i18n>
        </div>
      </div>
    </div>
    <div v-else class="free-shipping-bar-content">
      <div class="content-message">
        <i18n path="noticeBar.freeShipping.normalMsg" tag="div" for="normalFreeShipping">
          <span class="text-highlight">{{ $t('noticeBar.freeShipping.normalMsg_0') }}</span>
        </i18n>
      </div>
      <div class="content-buy">
        <a
          class="notice-text text-highlight"
          target="_self"
          href="https://shop.lollipop.camera/collections/lollipop-baby-camera/products/lollipop-baby-camera-us?variant=41463118135461&utm_source=official_website&utm_medium=free_shipping_bar&utm_campaign=free_shipping">{{ $t('noticeBar.freeShipping.buyNow') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { getRegretFreeShippingStatus } from '../../services/marketing';

export default {
  name: 'free-shipping-bar',
  mounted() {
    this.$nextTick(async () => {
      this.isRegretFreeShipping = await getRegretFreeShippingStatus();
    });
  },
  data() {
    return {
      isRegretFreeShipping: false,
    };
  },
};
</script>
<style lang="sass" scoped>
.text-highlight
  font-size: 16px
  font-weight: 700

.free-shipping-bar-wrapper
  --font-size: 14px
  background-color: transparent
  .free-shipping-bar-content
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    font-size: var(--font-size)
    line-height: 1.4
    font-weight: 400
    .content-message
      font-size: 400
      .inline-text
        display: inline-block
        white-space: pre-wrap
    .content-buy
      margin-left: 20px
      a
        color: #1A7BE2
        text-decoration: underline

@media screen and (max-width: 360px)
  .text-highlight
    font-size: 14px
  .free-shipping-bar-wrapper
    --font-size: 12px
</style>
