const languageUtil = {
  /**
   * 針對一些特別的 language code 做轉換
   * Default 取 "-" 前的字串，ex: en-US，則當作 en
   * @param {String} code Language Code
   * @returns {String} locale
   */
  codeTransfer(code) {
    const lowerCaseCode = code.toLowerCase();

    switch (lowerCaseCode) {
      case 'zh-tw':
        return 'zh-Hant';
      case 'zh-hk':
        return 'zh-Hant';
      default:
        return lowerCaseCode.split('-')[0];
    }
  },
};

export default languageUtil;
