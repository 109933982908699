<template>
  <div class="black-friday-bar-wrapper">
    <div class="black-friday-bar-content">
      <div class="content-message">
        <div class="inline-text">
          20% Off for Camera! Black Friday Only! From 26-30 Nov. 2021
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'black-friday-bar',
};
</script>
<style lang="sass" scoped>
.black-friday-bar-wrapper
  --font-size: 14px
  background-color: transparent
  .black-friday-bar-content
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    font-size: var(--font-size)
    line-height: 1.4
    font-weight: 700
    .content-message
      font-size: 400
      .inline-text
        display: inline-block
        white-space: pre-wrap

@media screen and (max-width: 360px)
  .black-friday-bar-wrapper
    --font-size: 12px
</style>
