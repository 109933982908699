<template>
  <div class="main-header-wrapper">
    <div class="header-bar">
      <nav>
        <ul class="menu-list">
          <!-- Camera -->
          <li
            class="menu-item menu-item-dropdown"
            @click.stop="toggleCameraSubMenu">
            {{ $t('header.camera.title') }}
            <div class="dropdown" :class="{'active': isCameraSubMenuActive}">
              <q-icon name="o_expand_more" />
            </div>
            <!-- Sub Menu of Camera -->
            <ul
              class="sub-menu-list sub-menu-list-camera"
              :class="{'active': isCameraSubMenuActive}">
              <li class="sub-menu-item">
                <router-link to="/product/camera">
                  {{ $t('header.camera.babyCamera') }}
                </router-link>
              </li>
              <li class="sub-menu-item">
                <a :href="btnSensorStoreLink" target="_self">{{ $t('header.camera.sensor') }}</a>
              </li>
              <li class="sub-menu-item">
                <a :href="btnAccessoriesStoreLink" target="_self">
                  {{ $t('header.camera.accessories') }}
                </a>
              </li>
            </ul>
            <!-- Sub Menu of Camera_End -->
          </li>
          <!-- Camera_End -->
          <!-- Care -->
          <li class="menu-item">
            <router-link to="/product/care">
              {{ $t('header.camera.lollipopCare') }}
            </router-link>
          </li>
          <!-- Care_End -->
          <!-- Feeding -->
          <li
            class="menu-item menu-item-dropdown"
            @click.stop="toggleFeedingSubMenu">
            {{ $t('header.feeding.title') }}
            <div class="dropdown" :class="{'active': isFeedingSubMenuActive}">
              <q-icon name="o_expand_more" />
            </div>
            <!-- Sub Menu of Feeding -->
            <ul
              class="sub-menu-list sub-menu-list-feeding"
              :class="{'active': isFeedingSubMenuActive}">
              <li class="sub-menu-item">
                <router-link to="/product/tableware">
                  {{ $t('header.feeding.tableware') }}
                </router-link>
              </li>
              <li class="sub-menu-item">
                <a :href="btnSilliconeFeedingBibLink" target="_self">
                  {{ $t('header.feeding.bib') }}
                </a>
              </li>
            </ul>
            <!-- Sub Menu of Feeding_End -->
          </li>
          <!-- Feeding_End -->
          <!-- Support -->
          <li class="menu-item menu-item-dropdown" @click.stop="toggleSupportSubMenu">
            {{ $t('header.support.title') }}
            <div class="dropdown" :class="{'active': isSupportSubMenuActive}">
              <q-icon name="o_expand_more" />
            </div>
            <!-- Sub Menu of Support -->
            <ul
              class="sub-menu-list sub-menu-list-support"
              :class="{'active': isSupportSubMenuActive}">
              <li class="sub-menu-item">
                <a href="https://support.lollipop.camera/hc/zh-tw" target="_blank">{{ $t('header.support.faq') }}</a>
              </li>
              <li class="sub-menu-item">
                <a href="https://support.lollipop.camera/hc/en-us/requests/new" target="_blank">{{ $t('header.support.contactUs') }}</a>
              </li>
            </ul>
            <!-- Sub Menu of Support_End -->
          </li>
           <!-- Support_End -->
          <li class="menu-item">
            <router-link to="/about">
              {{ $t('header.about.title') }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="header-logo">
        <router-link to="/">
          <img class="img-icon-logo" alt="Lollipop Logo" src="/media/img/logo.svg" />
        </router-link>
      </div>
      <div class="kit-list">
        <div class="kit-item language-selector-wrapper">
          <language-selector></language-selector>
        </div>
        <div class="kit-item kit-item-link web-app-wrapper">
          <a href="https://my.lollipop.camera/" target="_blank">
            <div class="link-btn">
              <img
                class="img-icon img-icon-account"
                src="/media/img/icons/header/account.svg?v=2"
                alt="Account">
            </div>
          </a>
        </div>
        <div class="kit-item kit-item-link store-wrapper">
          <a :href="btnOfficialStoreLink" target="_self">
            <div class="link-btn">
              <img
                class="img-icon img-icon-store"
                src="/media/img/icons/header/shopping_cart.svg?v=2"
                alt="Shopping Cart">
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LanguageSelector from '../components/LanguageSelector.vue';

export default {
  name: 'main-header',
  components: {
    LanguageSelector,
  },
  props: {
    isSubMenuClosed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isCameraSubMenuActive: false,
      isFeedingSubMenuActive: false,
      isSupportSubMenuActive: false,
    };
  },
  computed: {
    ...mapState({
      isHeaderFixed: (state) => state.isHeaderFixed,
      languageCode: (state) => state.languageCode,
    }),
    btnSilliconeFeedingBibLink() {
      const sCurrentLocale = this.$root.$i18n.locale;
      const url = `/product/sillicone-feeding-bib/?lang=${sCurrentLocale}`;
      return url;
    },
    btnOfficialStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/'
        : 'https://shopee.tw/lollipopbabycarestoretw?shopCollection=247173941#product_list';
    },
    btnSensorStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/products/lollipop-sensor-for-lollipop-baby-monitor-grey?variant=41463576232101'
        : 'https://shopee.tw/Lollipop-Sensor-%E6%A3%92%E6%A3%92%E7%B3%96%E7%9B%A3%E8%A6%96%E5%99%A8%E5%B0%88%E7%94%A8%E6%BA%AB%E6%BF%95%E5%BA%A6%E6%84%9F%E6%B8%AC%E5%99%A8(%E4%B8%8D%E5%8F%AF%E5%96%AE%E7%8D%A8%E4%BD%BF%E7%94%A8)-i.63372023.1097792525?sp_atk=558b0c8a-1aa5-49b5-a723-729d03ceabe5&xptdk=558b0c8a-1aa5-49b5-a723-729d03ceabe5';
    },
    btnAccessoriesStoreLink() {
      return this.languageCode !== 'zh-Hant'
        ? 'https://shop.lollipop.camera/collections/lollipop-accessories'
        : 'https://shopee.tw/shop/63372023/search?page=0&shopCollection=247173350';
    },
  },
  watch: {
    isSubMenuClosed() {
      this.reset();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      document.addEventListener('click', this.reset);
    },
    toggleCameraSubMenu() {
      const temp = !this.isCameraSubMenuActive;
      this.reset();
      this.isCameraSubMenuActive = temp;
    },
    toggleFeedingSubMenu() {
      const temp = !this.isFeedingSubMenuActive;
      this.reset();
      this.isFeedingSubMenuActive = temp;
    },
    toggleSupportSubMenu() {
      const temp = !this.isSupportSubMenuActive;
      this.reset();
      this.isSupportSubMenuActive = temp;
    },
    reset() {
      this.isCameraSubMenuActive = false;
      this.isFeedingSubMenuActive = false;
      this.isSupportSubMenuActive = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.main-header-wrapper
  width: 100%
  display: flex
  justify-content: center
  padding: 0 16px
  .header-bar
    position: relative
    width: $max-content-width
    height: $header-height
    display: flex
    align-items: center
    padding: 0 16px
    color: white
    font-weight: 400
    nav
      position: absolute
      top: 0px
      left: 160px
      display: inline-block
      font-size: 18px
      padding-left: 40px
      .menu-list
        display: flex
        align-items: center
        height: $header-height
        list-style-type: none
        margin: 0
        .menu-item
          height: $header-height
          margin-right: 40px
          user-select: none
          &:not(.menu-item-dropdown)
            color: $main-light-color
          &:hover
            color: #7ED4E6
            &:not(.menu-item-dropdown)
              a
                color: #7ED4E6
          &.menu-item-dropdown
            position: relative
            .dropdown
              margin-left: 4px
              margin-bottom: 0px
              &.active
                transform: rotate(180deg)
                margin-bottom: -4px
            .sub-menu-list
              width: max-content
              position: absolute
              top: $header-height
              left: -20px
              display: none
              justify-content: flex-start
              align-items: start
              flex-direction: column
              padding: 12px 20px
              background-color: $primary
              &.active
                display: flex
              .sub-menu-item
                width: 100%
                font-size: 16px
                text-align: left
                padding: 8px 0
                &:hover
                  a
                    color: #7ED4E6
                a
                  width: 100%
    .header-logo
      display: flex
      justify-content: center
      align-items: center
      height: 100%
      position: absolute
      top: 50%
      left: 0
      transform: translate(0%, -50%)
      a
        display: inline-block
        margin-bottom: -4px
        img
          width: 160px
          height: auto
    .kit-list
      height: 100%
      position: absolute
      top: 0px
      right: 0px
      display: flex
      align-items: center
      .kit-item
        margin-left: 16px
        &.kit-item-link
          height: 20px
          width: auto
          a
            color: #FFFFFF
            .link-btn
              display: flex
              align-items: center
              &:hover
                font-weight: 600
              .img-icon
                height: 20px
                width: auto
                font-size: 16px
                font-weight: inherit
                margin-right: 4px
        &.language-selector-wrapper
          display: inline-block

@media screen and (min-width: 1600px)
  .main-header-wrapper
    .header-bar
      width: $max-content-width-xl

@media screen and (max-width: 960px)
  .main-header-wrapper
    .header-bar
      nav
        .menu-list
          .menu-item
            margin-right: 16px
</style>
