<template>
  <div id="app">
    <main-layout></main-layout>
  </div>
</template>
<script>
import MainLayout from './layouts/MainLayout.vue';

export default {
  name: 'App',
  components: {
    MainLayout,
  },
  created() {
    this.checkIsCookieAccepted();
  },
  methods: {
    checkIsCookieAccepted() {
      /** 確認有無同意過 cookie */
      const isCookieAccepted = document.cookie.split(';').some((item) => item.includes('w3_ca=true'));
      this.$store.commit('updateIsCookieAccepted', isCookieAccepted);
    },
  },
};
</script>

<style lang="sass">
@import './styles/global';
</style>
